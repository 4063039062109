/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types */
import { SagaIterator } from '@redux-saga/types';
import { IAction } from 'lib/redux/models';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import {
  getAggregateDataService,
  getGeojsonService,
  getHeatmapDataService,
  getWorkorderDataService,
  WorkorderResponse,
  getWorkordersCSVService,
  getScoreboardDataService,
  getMetadataService,
  getWorkorderService,
  updateBotShutOffService,
  takeOwnershipService,
  toggleWorkingOnItService,
  toggleEscalatedService,
  getTradeOptionsService,
  getLocationsService,
  getProviderOptionsService,
  batchUpdateWorkingOnItService,
  batchUpdateEscalatedService,
  batchUpdateBotShutOffService,
  getAttachmentsService,
  errorHandler,
  APIError,
  getEquipmentTypeOptionsService,
  getCategoryOptionsService,
  getExtendedStatusOptionsService,
  getBsiConfigParamsService,
} from 'services/app';
import {
  actions,
  appendWorkorderData,
  clearFilters,
  selectPage,
  selectPageSize,
  setActiveWorkorder,
  setBotShutOff,
  setCallDate,
  setData,
  setDateRange,
  setFilterOptions,
  setGeojson,
  setLocations,
  setMaintenanceRepairOnly,
  setMetadata,
  setWorkorder,
  Types,
  updateWorkorder,
  warning,
} from '../duck';
import {
  AppData,
  AppFilters,
  AppPanelFilters,
  Attachment,
  BSIConfigParams,
  HeatmapCell,
  IAppState,
  NestedClub,
  NestedGeojson,
  Scoreboard,
  Workorder,
  WorkorderAggregate,
} from '../models';
import { AxiosResponse } from 'axios';
import { StringItem } from 'components/FilterBar/FilterMenu';
import { safeGetObjectValue } from 'utils/utils';

const { failure, fulfill, request } = actions;

/**
 * Saga to request the application metadata
 */
export function* getMetadataSaga() {
  yield put(request('loadingMetadata'));
  try {
    const metadata: IAppState['metadata'] = yield call(getMetadataService);
    if (metadata.version) {
      yield put(setMetadata(metadata));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingMetadata'));
  }
}

/**
 * Saga to request the regions and markets geojson from the backend
 */
export function* getGeojsonSaga() {
  yield put(request('loadingGeojson'));
  try {
    const geojson: NestedGeojson = yield call(getGeojsonService);
    if (geojson) {
      yield put(setGeojson(geojson));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingGeojson'));
  }
}

/**
 * Saga to request nested markets and clubs data from the backend
 */
export function* getLocationsSaga() {
  yield put(request('loadingGeojson'));
  try {
    const nestedClubs: NestedClub[] = yield call(getLocationsService);
    if (nestedClubs) {
      yield put(setLocations(nestedClubs));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingGeojson'));
  }
}

/**
 * Saga to get the aggregated data for the map donuts
 */
export function* getAggregateDataSaga(action: IAction) {
  yield put(request('loadingAggregates'));
  try {
    const aggregates: WorkorderAggregate[] = yield call(
      getAggregateDataService,
      action.payload.filters,
      action.payload.aggregationLevel,
    );
    if (aggregates) {
      yield put(setData({ aggregates }));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingAggregates'));
  }
}

/**
 * Saga to request the first page filtered work orders for the panel list
 */
export function* getWorkorderDataSaga(action: IAction) {
  yield put(request('loadingWorkorders'));
  try {
    const page: number = yield select(selectPage);
    const pageSize: number = yield select(selectPageSize);
    const data: Partial<AppData> = yield call(
      getWorkorderDataService,
      action.payload.filters,
      action.payload.panelFilters,
      action.payload.sortParam,
      page,
      pageSize,
    );
    if (data) {
      yield put(
        setData({
          workorders: data.workorders,
          totalWorkorders: data.totalWorkorders,
          frequentServiceProviders: data.frequentServiceProviders,
        }),
      );
    }
    if (
      data.workorders &&
      action.payload.filters.workorders &&
      action.payload.filters.workorders.length > data.workorders.length
    ) {
      yield put(
        warning({
          message:
            "Some of the work orders you searched for are not available in Battleship or don't match your current filters.",
        }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingWorkorders'));
  }
}

/**
 * Saga to request the generated CSV file for the current filters
 */
export function* getWorkordersCSVSaga(action: IAction) {
  yield put(request('loadingCSV'));
  try {
    const response: AxiosResponse = yield call(
      getWorkordersCSVService,
      action.payload.filters,
      action.payload.panelFilters,
      action.payload.sortParam,
    );
    if (response.data) {
      const el = document.createElement('a');
      const blob = new Blob(['\ufeff', response.data]);
      el.href = URL.createObjectURL(blob);
      el.download = 'battleship-work-orders.csv';
      el.click();
      el.remove();
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingCSV'));
  }
}

/**
 * Saga to request new pages of workorders for a current set of filters.
 * This is triggered while scrolling the workorder list.
 * New pages are appended to the workorders array in the data store.
 */
export function* paginateWorkorderDataSaga(action: IAction) {
  yield put(request('loadingWorkorders'));
  try {
    const page: number = yield select(selectPage);
    const pageSize: number = yield select(selectPageSize);
    const data: WorkorderResponse = yield call(
      getWorkorderDataService,
      action.payload.filters,
      action.payload.panelFilters,
      action.payload.sortParam,
      page + 1,
      pageSize,
    );
    if (data) {
      yield put(appendWorkorderData(data.workorders));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingWorkorders'));
  }
}

/**
 * Saga to request binned data for the workorder heatmap
 */
export function* getHeatmapDataSaga(action: IAction) {
  yield put(request('loadingHeatmap'));
  try {
    const heatmap: HeatmapCell[] = yield call(
      getHeatmapDataService,
      action.payload.filters,
    );
    if (heatmap) {
      yield put(setData({ heatmap }));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingHeatmap'));
  }
}

/**
 * Saga to toggle the working_on_it field for a specific workorder.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* toggleWorkingOnItSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const response: Partial<Workorder> = yield call(
      toggleWorkingOnItService,
      action.payload.workorderId,
    );
    if (response) {
      yield put(
        updateWorkorder({
          workorderId: action.payload.workorderId,
          data: response,
        }),
      );
    }
  } catch (error: any) {
    if (
      error.response.data.error.includes(
        'Only this user can remove the check mark',
      )
    ) {
      yield put(
        warning({ message: error.response.data.error, data: action.payload }),
      );
    } else if (error.response.data.error) {
      yield put(failure({ message: error.response.data.detail }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to batch update working on it.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* batchUpdateWorkingOnItSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const response: Partial<Workorder> = yield call(
      batchUpdateWorkingOnItService,
      action.payload.workorders,
      action.payload.value,
    );
    if (response) {
      for (const index in response) {
        const workorder = safeGetObjectValue(response, index) as any;
        yield put(
          updateWorkorder({
            workorderId: workorder.work_order as number,
            data: workorder.stats,
          }),
        );
      }
    }
  } catch (error: any) {
    if (error.response.data.error) {
      yield put(failure({ message: error.response.data.error }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to toggle the escalated field for a specific workorder.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* toggleEscalatedSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const response: Partial<Workorder> = yield call(
      toggleEscalatedService,
      action.payload.workorderId,
    );
    if (response) {
      yield put(
        updateWorkorder({
          workorderId: action.payload.workorderId,
          data: response,
        }),
      );
    }
  } catch (error: any) {
    if (error.response.data.error) {
      yield put(failure({ message: error.response.data.error }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to batch update escalated.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* batchUpdateEscalatedSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const response: Partial<Workorder> = yield call(
      batchUpdateEscalatedService,
      action.payload.workorders,
      action.payload.value,
    );
    if (response) {
      for (const index in response) {
        const workorder = safeGetObjectValue(response, index) as any;
        yield put(
          updateWorkorder({
            workorderId: workorder.work_order as number,
            data: workorder.stats,
          }),
        );
      }
    }
  } catch (error: any) {
    if (error.response.data.error) {
      yield put(failure({ message: error.response.data.error }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to take ownerwhip of the working_on_it field for a specific workorder.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* takeOwnershipSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const data: Partial<Workorder> = yield call(
      takeOwnershipService,
      action.payload.workorderId,
    );
    if (data) {
      yield put(
        updateWorkorder({
          workorderId: action.payload.workorderId,
          data: data,
        }),
      );
    }
  } catch (error: any) {
    if (error.response.data.error) {
      yield put(failure({ message: error.response.data.error }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to update the bot shut off field for a specific workorder.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* updateBotShutOffSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const response: boolean = yield call(
      updateBotShutOffService,
      action.payload.workorderId,
    );
    if (response) {
      yield put(
        setBotShutOff({
          workorderId: action.payload.workorderId,
          value: action.payload.value,
          user: action.payload.user,
        }),
      );
    }
  } catch (error: any) {
    if (error.response.data.error) {
      yield put(failure({ message: error.response.data.error }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to batch update bot shut off.
 * If successful, the workorder item is updated within the workorder array in the data store.
 */
export function* batchUpdateBotShutOffSaga(action: IAction) {
  yield put(request('loading'));
  try {
    const response: { work_order: number }[] = yield call(
      batchUpdateBotShutOffService,
      action.payload.workorders,
      action.payload.value,
    );
    if (response) {
      for (const index in response) {
        const workorder = safeGetObjectValue(response, index) as any;
        yield put(
          setBotShutOff({
            workorderId: workorder.work_order,
            value: action.payload.value,
            user: action.payload.user,
          }),
        );
      }
    }
  } catch (error: any) {
    if (error.response.data.error) {
      yield put(failure({ message: error.response.data.error }));
    } else {
      yield put(failure({ message: error.message }));
    }
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to request binned data for the workorder heatmap
 */
export function* getScoreboardDataSaga() {
  yield put(request('loadingScoreboard'));
  try {
    const scoreboard: Scoreboard = yield call(getScoreboardDataService);
    if (scoreboard) {
      yield put(setData({ scoreboard }));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingScoreboard'));
  }
}

/**
 * Saga for fetching an workorder by id and current filters
 */
export function* getWorkorderSaga(action: IAction) {
  yield put(request('loadingWorkorder'));
  try {
    const filteredWorkorder: Workorder = yield call(
      getWorkorderService,
      action.payload.id,
      action.payload.filters,
    );
    if (filteredWorkorder) {
      if (action.payload.updateActiveWorkorder) {
        yield put(
          setActiveWorkorder({
            id: action.payload.id,
            linked_work_orders_follow_up:
              filteredWorkorder.linked_work_orders_follow_up,
            linked_work_orders_original:
              filteredWorkorder.linked_work_orders_original,
            potential_duplicates: filteredWorkorder.potential_duplicates,
          }),
        );
      } else {
        yield put(setActiveWorkorder(filteredWorkorder));
        yield put(setWorkorder(filteredWorkorder.id));
      }
    }
  } catch (error: any) {
    if (error.response.status === 404) {
      try {
        const workorder: Workorder = yield call(
          getWorkorderService,
          action.payload.id,
          { maintenanceRepairOnly: true } as AppFilters,
        );
        if (workorder) {
          yield put(
            warning({
              message:
                'The work order you searched for is not available with your current filter selection. Filters have been adjusted.',
            }),
          );
          yield put(clearFilters());
          yield put(setMaintenanceRepairOnly(true));
          const createdDate =
            workorder.created_date &&
            new Date(workorder.created_date).getTime() / 1000;
          if (
            createdDate &&
            createdDate > action.payload.filters.callDate.end
          ) {
            yield put(setCallDate({ end: createdDate }));
          } else if (
            createdDate &&
            createdDate < action.payload.filters.callDate.start
          ) {
            yield put(setCallDate({ start: createdDate }));
          }
          yield put(setDateRange({ name: 'Custom', value: 'custom' }));
          yield put(setActiveWorkorder(workorder));
          yield put(setWorkorder(workorder.id));
        }
      } catch (error: any) {
        if (error?.response.data.detail) {
          if (
            error.response.status === 404 &&
            error.response.data.detail == 'Not found.'
          ) {
            yield put(
              warning({
                message:
                  'Work order not found in Battleship database. However, it was found in',
                data: { workorderId: action.payload.id },
              }),
            );
            yield put(setActiveWorkorder(undefined));
            yield put(setWorkorder(undefined));
          } else {
            yield put(failure({ message: error.response.data.detail }));
          }
        } else {
          yield put(failure({ message: error.message }));
        }
      }
    }
  } finally {
    yield put(fulfill('loadingWorkorder'));
  }
}

/**
 * Saga to request filters options
 */
export function* getFiltersOptionsSaga() {
  yield put(request('loading'));
  try {
    const { tradeOptions, groupedTradeOptions } = yield call(
      getTradeOptionsService,
    );
    const providerOptions: StringItem[] = yield call(getProviderOptionsService);
    const categoriesOptions: StringItem[] = yield call(
      getCategoryOptionsService,
    );
    const equipmentTypesOptions: StringItem[] = yield call(
      getEquipmentTypeOptionsService,
    );
    const extendedStatusOptions: StringItem[] = yield call(
      getExtendedStatusOptionsService,
    );
    if (tradeOptions && groupedTradeOptions) {
      yield put(
        setFilterOptions({
          providers: providerOptions,
          trades: tradeOptions,
          tradeGroups: groupedTradeOptions,
          categories: categoriesOptions,
          equipmentTypes: equipmentTypesOptions,
          extendedStatus: extendedStatusOptions,
        }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loading'));
  }
}

/**
 * Saga to request linked workorders data
 */
export function* getLinkedWorkordersSaga(action: IAction) {
  yield put(request('loadingLinkedWorkorders'));
  try {
    const original = action.payload.original as number[];
    const followUp = action.payload.followUp as number[];
    const workorders = [...original, ...followUp];
    const data: WorkorderResponse = yield call(
      getWorkorderDataService,
      { workorders: workorders } as AppFilters,
      {} as AppPanelFilters,
      ['combined_index'],
      1,
      50,
    );
    if (data) {
      const originalWOs = data.workorders.filter((wo: Workorder) =>
        original.includes(wo.id),
      );
      const followUpWOs = data.workorders.filter((wo: Workorder) =>
        followUp.includes(wo.id),
      );
      yield put(
        setData({
          linkedWorkorderOriginal: originalWOs,
          linkedWorkorderFollowUp: followUpWOs,
        }),
      );
    }
    if (data.workorders && workorders.length > data.workorders.length) {
      yield put(
        warning({
          message:
            'Some of the linked work orders are not available in Battleship.',
        }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingLinkedWorkorders'));
  }
}

/**
 * Saga to request linked workorders data
 */
export function* getPotentialDuplicatesSaga(action: IAction) {
  yield put(request('loadingPotentialDuplicates'));
  try {
    const workorders = action.payload as number[];
    const data: WorkorderResponse = yield call(
      getWorkorderDataService,
      { workorders: workorders } as AppFilters,
      {} as AppPanelFilters,
      ['combined_index'],
      1,
      50,
    );
    if (data) {
      const potentialDuplicates = data.workorders.filter((wo: Workorder) =>
        workorders.includes(wo.id),
      );
      yield put(
        setData({
          potentialDuplicates: potentialDuplicates,
        }),
      );
    }
    if (data.workorders && workorders.length > data.workorders.length) {
      yield put(
        warning({
          message:
            'Some of the potential duplicate work orders are not available in Battleship.',
        }),
      );
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingPotentialDuplicates'));
  }
}

/**
 * Saga to request workorder attachments
 */
export function* getAttachmentsSaga(action: IAction) {
  yield put(request('loadingAttachments'));
  yield put(setData({ attachments: undefined }));
  try {
    const data: Attachment[] = yield call(
      getAttachmentsService,
      action.payload,
    );
    if (data) {
      yield put(setData({ attachments: data }));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingAttachments'));
  }
}

/**
 * Saga to request BSI config params
 */
export function* getBsiConfigParamsSaga() {
  yield put(request('loadingBsiConfigParams'));
  try {
    const data: BSIConfigParams = yield call(getBsiConfigParamsService);
    if (data) {
      yield put(setData({ bsiConfigParams: data }));
    }
  } catch (error) {
    yield errorHandler(error as APIError, (payload) => put(failure(payload)));
  } finally {
    yield put(fulfill('loadingBsiConfigParams'));
  }
}

export default function* appSagas(): SagaIterator {
  yield all([
    takeLatest(Types.GET_METADATA, getMetadataSaga),
    takeLatest(Types.GET_GEOJSON, getGeojsonSaga),
    takeLatest(Types.GET_LOCATIONS, getLocationsSaga),
    takeLatest(Types.GET_AGGREGATE_DATA, getAggregateDataSaga),
    takeLatest(Types.GET_WORKORDER_DATA, getWorkorderDataSaga),
    takeLatest(Types.GET_WORKORDERS_CSV, getWorkordersCSVSaga),
    takeLatest(Types.GET_HEATMAP_DATA, getHeatmapDataSaga),
    takeLatest(Types.GET_WORKORDER, getWorkorderSaga),
    takeLatest(Types.PAGINATE_WORKORDER_DATA, paginateWorkorderDataSaga),
    takeLatest(Types.TOGGLE_WORKING_ON_IT, toggleWorkingOnItSaga),
    takeLatest(Types.BATCH_UPDATE_WORKING_ON_IT, batchUpdateWorkingOnItSaga),
    takeLatest(Types.TOGGLE_ESCALATED, toggleEscalatedSaga),
    takeLatest(Types.BATCH_UPDATE_ESCALATED, batchUpdateEscalatedSaga),
    takeLatest(Types.TAKE_OWNERSHIP, takeOwnershipSaga),
    takeLatest(Types.UPDATE_BOT_SHUT_OFF, updateBotShutOffSaga),
    takeLatest(Types.BATCH_UPDATE_BOT_SHUT_OFF, batchUpdateBotShutOffSaga),
    takeLatest(Types.GET_SCOREBOARD_DATA, getScoreboardDataSaga),
    takeLatest(Types.GET_FILTER_OPTIONS, getFiltersOptionsSaga),
    takeLatest(Types.GET_LINKED_WORKORDERS, getLinkedWorkordersSaga),
    takeLatest(Types.GET_POTENTIAL_DUPLICATES, getPotentialDuplicatesSaga),
    takeLatest(Types.GET_ATTACHMENTS, getAttachmentsSaga),
    takeLatest(Types.GET_BSI_CONFIG_PARAMS, getBsiConfigParamsSaga),
  ]);
}
