// Models
import { StringItem } from 'components/FilterBar/FilterMenu';
import { FeatureCollection } from 'geojson';
import { IBaseState } from 'lib/redux/models';

export enum EAppActionTypes {
  FAILURE = 'APP/FAILURE',
  WARNING = 'APP/WARNING',
  FULFILL = 'APP/FULFILL',
  REQUEST = 'APP/REQUEST',
  CLEAR_STATE = 'APP/CLEAR_STATE',
  GET_METADATA = 'APP/GET_METADATA',
  SET_METADATA = 'APP/SET_METADATA',
  GET_GEOJSON = 'APP/GET_GEOJSON',
  SET_GEOJSON = 'APP/SET_GEOJSON',
  GET_LOCATIONS = 'APP/GET_LOCATIONS',
  SET_LOCATIONS = 'APP/SET_LOCATIONS',
  SET_DATA = 'APP/SET_DATA',
  GET_AGGREGATE_DATA = 'APP/GET_AGGREGATE_DATA',
  GET_WORKORDER_DATA = 'APP/GET_WORKORDER_DATA',
  GET_WORKORDERS_CSV = 'APP/GET_WORKORDERS_CSV',
  PAGINATE_WORKORDER_DATA = 'APP/PAGINATE_WORKORDER_DATA',
  APPEND_WORKORDER_DATA = 'APP/APPEND_WORKORDER_DATA',
  GET_HEATMAP_DATA = 'APP/GET_HEATMAP_DATA',
  SELECT_HEATMAP_CELL = 'APP/SELECT_HEATMAP_CELL',
  SET_ZOOM = 'APP/SET_ZOOM',
  SET_LAT = 'APP/SET_LAT',
  SET_LNG = 'APP/SET_LNG',
  SET_REGION = 'APP/SET_REGION',
  SET_MARKET = 'APP/SET_MARKET',
  SET_CLUB = 'APP/SET_CLUB',
  SET_FILTER = 'APP/SET_FILTER',
  SET_DATE_RANGE = 'APP/SET_DATE_RANGE',
  SET_CUSTOM_DATE_RANGE = 'APP/SET_CUSTOM_DATE_RANGE',
  SET_CALL_DATE = 'APP/SET_CALL_DATE',
  GET_WORKORDER = 'APP/GET_WORKORDER',
  SET_WORKORDER = 'APP/SET_WORKORDER',
  SET_ACTIVE_WORKORDER = 'APP/SET_ACTIVE_WORKORDER',
  SET_SORT_FIELD = 'APP/SET_SORT_FIELD',
  SET_SORT_ASC = 'APP/SET_SORT_ASC',
  SET_MAP = 'APP/SET_MAP',
  SET_IS_MAP_LOADED = 'APP/SET_IS_MAP_LOADED',
  SET_HOVERED_FILL = 'APP/SET_HOVERED_FILL',
  SET_HOVERED_AGGREGATE = 'APP/SET_HOVERED_AGGREGATE',
  SET_HOVERED_WORKORDER = 'APP/SET_HOVERED_WORKORDER',
  CLEAR_FILTERS = 'APP/CLEAR_FILTERS',
  SET_SHOW_CROSSHAIR = 'APP/SET_SHOW_CROSSHAIR',
  TOGGLE_WORKING_ON_IT = 'APP/TOGGLE_WORKING_ON_IT',
  BATCH_UPDATE_WORKING_ON_IT = 'APP/BATCH_UPDATE_WORKING_ON_IT',
  TOGGLE_ESCALATED = 'APP/TOGGLE_ESCALATED',
  BATCH_UPDATE_ESCALATED = 'APP/BATCH_UPDATE_ESCALATED',
  TAKE_OWNERSHIP = 'APP/TAKE_OWNERSHIP',
  UPDATE_WORKORDER = 'APP/UPDATE_WORKORDER',
  UPDATE_BOT_SHUT_OFF = 'APP/UPDATE_BOT_SHUT_OFF',
  SET_BOT_SHUT_OFF = 'APP/SET_BOT_SHUT_OFF',
  BATCH_UPDATE_BOT_SHUT_OFF = 'APP/BATCH_UPDATE_BOT_SHUT_OFF',
  GET_SCOREBOARD_DATA = 'APP/GET_SCOREBOARD_DATA',
  UPDATE_FILTERS = 'APP/UPDATE_FILTERS',
  SET_WORKORDER_LIST_FULLSCREEN = 'APP/SET_WORKORDER_LIST_FULLSCREEN',
  TOGGLE_MAP_SELECTION = 'APP/TOGGLE_MAP_SELECTION',
  GET_FILTER_OPTIONS = 'APP/GET_FILTER_OPTIONS',
  SET_FILTER_OPTIONS = 'APP/SET_FILTER_OPTIONS',
  TOGGLE_SIDE_PANEL_OPEN = 'APP/TOGGLE_SIDE_PANEL_OPEN',
  TOGGLE_NOTES_PANEL_OPEN = 'APP/TOGGLE_NOTES_PANEL_OPEN',
  TOGGLE_LINKED_WO_PANEL_OPEN = 'APP/TOGGLE_LINKED_WO_PANEL_OPEN',
  GET_LINKED_WORKORDERS = 'APP/GET_LINKED_WORKORDERS',
  SET_LINKED_WORKORDERS = 'APP/SET_LINKED_WORKORDERS',
  TOGGLE_POTENTIAL_DUPLICATES_PANEL_OPEN = 'APP/TOGGLE_POTENTIAL_DUPLICATES_PANEL_OPEN',
  GET_POTENTIAL_DUPLICATES = 'APP/GET_POTENTIAL_DUPLICATES',
  SET_POTENTIAL_DUPLICATES = 'APP/SET_POTENTIAL_DUPLICATES',
  TOGGLE_ATTACHMENTS_PANEL_OPEN = 'APP/TOGGLE_ATTACHMENTS_PANEL_OPEN',
  GET_ATTACHMENTS = 'APP/GET_ATTACHMENTS',
  SELECT_USER_VIEW = 'APP/SELECT_USER_VIEW',
  SELECT_TEAM_VIEW = 'APP/SELECT_TEAM_VIEW',
  GET_BSI_CONFIG_PARAMS = 'APP/GET_BSI_CONFIG_PARAMS',
}

export interface WorkorderAggregate {
  id: number;
  name: string | number;
  aggregation_level: 'Region' | 'Market' | 'Club';
  region?: string;
  market?: number;
  markets?: number[];
  clubs?: string[];
  total_workorders: number;
  low_priority_work_orders: number;
  medium_priority_work_orders: number;
  high_priority_work_orders: number;
  total_nte: number;
  center_lat: number;
  center_lng: number;
  cluster?: boolean;
  cluster_id?: number;
  point_count?: number;
}

export interface Attachment {
  name: string;
  uri: string;
  description: string;
  timestamp: string;
}

export interface Workorder {
  id: number;
  region_id?: number;
  market_id?: number;
  club_id?: string;
  region_name?: string;
  proposals?: Proposal[];
  selected?: boolean;
  working_on_it?: boolean;
  working_on_it_user?: string;
  is_escalated?: boolean;
  escalated_user?: string;
  bot_shut_off?: boolean;
  is_bot_shut_off?: boolean;
  bot_shut_off_user?: string;
  priority_bin?: string;
  urgency_scaled?: number;
  importance_scaled?: number;
  combined_index?: number;
  club_address?: string;
  club_city?: string;
  club_state?: string;
  club_zip?: string;
  club_main_contact?: string;
  club_phone_number?: string;
  club_manager_name?: string;
  club_manager_cell_phone?: string;
  club_latitude?: number;
  club_longitude?: number;
  linked_work_orders_original?: string[];
  linked_work_orders_follow_up?: string[];
  potential_duplicates?: Workorder['id'][];
  number?: string;
  purchase_number?: string;
  problem_code?: string;
  description?: string;
  resolution?: string;
  equipment?: {
    Area?: string;
  };
  equipment_id?: number;
  equipment_tag?: string;
  equipment_type_id?: number;
  equipment_type?: string;
  provider_id?: number;
  provider_name?: string;
  provider_phone?: string;
  trade?: string;
  priority?: string;
  category?: string;
  primary_status?: string;
  extended_status?: string;
  caller?: string;
  created_by?: string;
  provider_rank?: number;
  approval_code?: string;
  asset_count?: number;
  posted_id?: number;
  is_check_in_denied?: boolean;
  is_invoiced?: boolean;
  is_expired?: boolean;
  is_enabled_for_mobile?: boolean;
  has_work_activity?: boolean;
  caller_id?: number;
  auto_complete?: boolean;
  auto_invoice?: boolean;
  check_in_range?: number;
  can_create_invoice?: boolean;
  call_date?: string;
  call_date_dto?: string;
  scheduled_date?: string;
  scheduled_date_dto?: string;
  completed_date?: string;
  completed_date_dto?: string;
  expiration_date?: string;
  updated_date?: string;
  updated_date_dto?: string;
  created_date?: string;
  created_date_dto?: string;
  nte?: number;
  location?: number;
  trade_group?: number;
}

export interface HeatmapCell {
  urgencyGroup: number;
  importanceGroup: number;
  count: number;
  mean: number;
  median: number;
}

export interface Scoreboard {
  [username: string]: number;
}

export interface Proposal {
  proposal_id: number;
  proposal_number: number;
  proposal_status: string;
  proposal_date: string;
}

export interface FrequentServiceProvider {
  name: string;
  count: number;
  percentage: number;
}

export interface AppData {
  aggregates?: WorkorderAggregate[];
  workorders?: Workorder[];
  totalWorkorders?: number;
  heatmap?: HeatmapCell[];
  scoreboard?: Scoreboard;
  activeWorkorder?: Workorder;
  linkedWorkorderOriginal?: Workorder[];
  linkedWorkorderFollowUp?: Workorder[];
  potentialDuplicates?: Workorder[];
  attachments?: Attachment[];
  bsiConfigParams?: BSIConfigParams;
  frequentServiceProviders?: FrequentServiceProvider[];
}

export interface AppFilters {
  workorders?: number[];
  region?: string[];
  market?: number[];
  club?: string[];
  trades?: string[];
  tradeGroups?: string[];
  providers?: string[];
  categories?: string[];
  status?: string[];
  bsi?: string[];
  nte?: StringItem;
  workingOnIt?: StringItem;
  escalated?: StringItem;
  notOperationalOnly?: boolean;
  potentialDuplicateOnly?: boolean;
  missedETA?: boolean;
  maintenanceRepairOnly?: boolean;
  dateRange: StringItem;
  callDate: {
    start?: number;
    end?: number;
  };
  extendedStatus?: string[];
  importance?: number;
  urgency?: number;
  noActivitySince?: StringItem;
  equipmentTypes?: string[];
  workingOnItUser?: string;
  escalatedUser?: string;
  workorder?: number;
}

export interface AppPanelFilters {
  working_on_it?: boolean;
}

export enum AggregationLevel {
  REGION = 'Region',
  MARKET = 'Market',
  CLUB = 'Club',
}

export interface NestedGeojson {
  regions: FeatureCollection;
  markets: FeatureCollection;
}

export interface NestedClub {
  region_name: string;
  markets: {
    market_id: number;
    locations: {
      id: number;
      store_id: string;
    }[];
  }[];
}

export interface BSIConfigParams {
  bsi_boosting: {
    boost: number;
    category: string;
    function: string;
    is_active: boolean;
    problem_code: string;
  }[];
  bsi_top_boosting: {
    [key: string]: boolean;
  };
  categories: string[];
  category_weights: {
    [key: string]: number;
  };
  primary_status_weights: {
    [key: string]: number;
  };
  priority_weights: {
    [key: string]: number;
  };
  problem_code_weights: {
    [key: string]: number;
  };
  trade_group_weights: {
    [key: string]: number;
  };
  trades: {
    [key: string]: string;
  };
}

export interface IAppState extends IBaseState {
  metadata: {
    version?: string;
    bsi_updated_date?: string;
    sc_portal_url?: string;
    xp_multipliers?: {
      working_on_it: number;
      escalated: number;
      note: number;
    };
    bot_status?: {
      display_name: string;
      is_active: boolean;
    }[];
    broadcast_message?: string;
    loaded?: boolean;
  };
  initialized: boolean;
  zoom: number;
  lat: number;
  lng: number;
  filters: AppFilters;
  filtersOptions: {
    trades: StringItem[];
    tradeGroups: StringItem[];
    providers: StringItem[];
    categories: StringItem[];
    equipmentTypes: StringItem[];
    extendedStatus: StringItem[];
  };
  panelFilters: AppPanelFilters;
  nestedClubs?: NestedClub[];
  regionMarkets?: { [key: string]: number[] };
  regionClubs?: { [key: string]: string[] };
  marketClubs?: { [key: string]: string[] };
  map?: maplibregl.Map;
  isMapLoaded: boolean;
  data: AppData;
  sortField: string;
  sortAsc: boolean;
  page: number;
  pageSize: number;
  geojson?: NestedGeojson;
  hoveredFill?: WorkorderAggregate;
  hoveredAggregate?: WorkorderAggregate;
  hoveredWorkorder?: Workorder;
  loadingMetadata?: boolean;
  loadingGeojson?: boolean;
  loadingAggregates?: boolean;
  loadingWorkorders?: boolean;
  loadingWorkorder?: boolean;
  loadingHeatmap?: boolean;
  loadingScoreboard?: boolean;
  loadingCSV?: boolean;
  loadingLinkedWorkorders?: boolean;
  loadingPotentialDuplicates?: boolean;
  loadingAttachments?: boolean;
  loadingBsiConfigParams?: boolean;
  showCrosshair?: boolean;
  isFullscreen: boolean;
  isSidePanelOpen: boolean;
  isNotesPanelOpen: boolean;
  isLinkedWOPanelOpen: boolean;
  isPotentialDuplicatesPanelOpen: boolean;
  isAttachmentPanelOpen: boolean;
  isMapSelectionActive: boolean;
}

/**
 * Values that are derived from app state variables
 * but not explicitply stored in the state.
 */
export interface IAppStateDerived {
  regionOptions: string[];
  marketOptions: number[];
  clubOptions: string[];
  aggregationLevel: AggregationLevel;
  highlightedAggregate?: WorkorderAggregate;
  sortParam: string[];
}
