import React, { FC, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IStore from 'lib/redux/models';
import { IAuthState, TeamView } from 'storage/auth/models';
import { Stack, Card, CardContent, CardActions, Button, TextField, Typography, CircularProgress, Modal, IconButton, Tooltip, Icon, Checkbox } from '@mui/material';
import theme from 'theme';
import { deleteTeamView, saveTeamView, saveTeamViewAsDefault, sortTeamViews } from 'storage/auth/duck';
import { IAppState } from 'storage/app/models';
import DeleteIcon from '@mui/icons-material/Delete';
import { selectTeamView, setCallDate, setDateRange } from 'storage/app/duck';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { trackEvent } from 'storage/tracking/duck';


const TruncatedLabel: FC<{ label: string, options: string[] }> = ({ label, options }) => {
  const title = options.join(", ")
  if (title.length > 20) {
    return <Tooltip title={title} placement='right'>
      <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
        {label}: {title.slice(0, 20)}...
      </Typography>
    </Tooltip>
  } else {
    return <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
      {label}: {title}
    </Typography>
  }
}


interface SavedTeamViewProps {
  view: TeamView
  selectTeamView: (view: TeamView) => void
  deleteTeamView?: (e: MouseEvent, id: TeamView["id"]) => void
  saveAsDefault?: (e: MouseEvent, id: TeamView["id"], value: boolean) => void
}

export const SavedTeamView: FC<SavedTeamViewProps> = ({ view, selectTeamView, deleteTeamView, saveAsDefault }) => {
  return (
    <Button
      component={'div'}
      onClick={() => selectTeamView(view)}
      sx={{
        textTransform: 'unset',
        paddingBottom: '0.25rem',
        marginBottom: '0.25rem',
        backgroundColor: theme.palette.background.paper,
        ':last-child': {
          borderBottom: 'unset', paddingBottom: 'unset', marginBottom: 'unset'
        }
      }}
    >
      <Stack alignItems='flex-start' width='20rem' padding='0.5rem 2.5rem 0.5rem 0.5rem' minHeight={'6rem'}>
        {view.description &&
          <Typography variant="h4" sx={{ fontSize: "1.25rem", mb: '0.25rem' }}>
            {view.description}
          </Typography>
        }
        {view.state.dateRange && view.state.dateRange.value !== "custom" ?
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            {view.state.dateRange.name}
          </Typography> :
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            From: {
              view.state.callDate.start && new Date(view.state.callDate.start * 1000).toLocaleDateString()
            } To: {
              view.state.callDate.end && new Date(view.state.callDate.end * 1000).toLocaleDateString()
            }
          </Typography>
        }
        {view.state.trades &&
          <TruncatedLabel label="Trades" options={view.state.trades} />
        }
        {view.state.tradeGroups &&
          <TruncatedLabel label="Trade Groups" options={view.state.tradeGroups} />
        }
        {view.state.providers &&
          <TruncatedLabel label="Providers" options={view.state.providers} />
        }
        {view.state.categories &&
          <TruncatedLabel label="Category" options={view.state.categories} />
        }
        {view.state.status &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Status: {view.state.status.join(", ")}
          </Typography>
        }
        {view.state.bsi &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            BSI: {view.state.bsi.map(bsi => bsi.charAt(0).toUpperCase() + bsi.slice(1)).join(", ")}
          </Typography>
        }
        {view.state.nte &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            NTE: {typeof (view.state.nte.name) === 'string' && view.state.nte.name.replace("NTE ", "")}
          </Typography>
        }
        {view.state.workingOnIt &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Follow up: {view.state.workingOnIt.name}
          </Typography>
        }
        {view.state.escalated &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Escalated: {view.state.escalated.name}
          </Typography>
        }
        {view.state.noActivitySince &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            No activity since {view.state.noActivitySince.name}
          </Typography>
        }
        {view.state.equipmentTypes &&
          <TruncatedLabel label="Equipment" options={view.state.equipmentTypes} />
        }
        {view.state.notOperationalOnly &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Not operational equipment only
          </Typography>
        }
        {view.state.missedETA &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Scheduled date in the past
          </Typography>
        }
        {view.state.potentialDuplicateOnly &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Potential duplicates only
          </Typography>
        }
        {view.state.maintenanceRepairOnly &&
          <Typography variant="h6" sx={{ fontSize: "0.9rem" }}>
            Display Maintenance and Repair
          </Typography>
        }
      </Stack>
      {view.is_default && !saveAsDefault &&
        <Icon sx={{ position: 'absolute', top: '0.5rem', right: '0.75rem' }}>
          {<StarIcon />}
        </Icon>
      }
      {saveAsDefault &&
        <Tooltip title="Save this view as the default for your team" placement='right'>
          <IconButton onClick={(e) => saveAsDefault(e, view.id, !view.is_default)} sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem' }}>
            {view.is_default ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>
        </Tooltip>
      }
      {deleteTeamView &&
        <Tooltip title="Delete this view" placement='right'>
          <IconButton onClick={(e) => deleteTeamView(e, view.id)} sx={{ position: 'absolute', bottom: '0.5rem', right: '0.5rem' }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      }
    </Button>
  )
}


interface TeamViewsModalProps {
  open: boolean;
  closeModal: () => void;
}

const TeamViewsModal: FC<TeamViewsModalProps> = (props) => {
  const dispatch = useDispatch();
  const views = useSelector<IStore, IAuthState['teamViews']>((state) => state.auth.teamViews);
  const filters = useSelector<IStore, IAppState['filters']>((state) => state.app.filters);
  const loading = useSelector<IStore, IAuthState['loading']>((state) => state.auth.loading);
  const [viewDescription, setViewDescription] = useState<string>("");
  const [viewIsDefault, setViewIsDefault] = useState<boolean>(false);

  const handleSelectView = (view: TeamView) => {
    dispatch(selectTeamView(view.state))
    if (view.state.dateRange && view.state.dateRange.value !== "custom") {
      dispatch(setDateRange(view.state.dateRange))
    } else {
      dispatch(setDateRange({ name: "Custom", value: "custom" }))
      dispatch(setCallDate(view.state.callDate))
    }
    dispatch(trackEvent({ namespace: 'Select team view', predicate: 'Team views', payload: { id: view.id } }))
    handleCloseModal()
  }

  const handleSaveTeamView = () => {
    setViewDescription("");
    dispatch(trackEvent({ namespace: 'Save team view', predicate: 'Team views', payload: { ...filters, description: viewDescription, is_default: viewIsDefault } }))
    dispatch(saveTeamView({ state: filters, description: viewDescription, is_default: viewIsDefault }));
  }

  const handleDeleteTeamView = (e: MouseEvent, id: TeamView["id"]) => {
    e.stopPropagation();
    dispatch(trackEvent({ namespace: 'Delete team view', predicate: 'Team views', payload: { id } }))
    dispatch(deleteTeamView(id));
  }

  const handleSaveAsDefault = (e: MouseEvent, id: TeamView["id"], value: boolean) => {
    e.stopPropagation();
    dispatch(trackEvent({ namespace: 'Set team view as default', predicate: 'Team views', payload: { id } }))
    dispatch(saveTeamViewAsDefault({ id, is_default: value }));
  }

  const handleCloseModal = () => {
    dispatch(sortTeamViews())
    props.closeModal()
  }


  return (
    <Modal
      open={props.open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Card sx={{ padding: "2rem", position: 'relative', backgroundColor: theme.palette.background.default }}>
        <Button
          variant='text'
          size='small'
          onClick={handleCloseModal}
          sx={{ position: 'absolute', top: '0.5rem', right: '0.5rem', color: theme.palette.text.primary, minWidth: '2rem', height: '2rem' }}
        >
          <CloseIcon />
        </Button>
        <CardContent>
          <Stack gap={2}>
            <Typography variant="h2" sx={{ textAlign: "center", fontSize: "1.75rem", marginBottom: "1rem" }}>
              Saved Team Views
            </Typography>
            <Stack sx={{ maxHeight: '50svh', overflow: 'auto', paddingRight: '0.25rem' }}>
              {views.map((view) =>
                <SavedTeamView key={view.id} view={view} selectTeamView={handleSelectView} deleteTeamView={handleDeleteTeamView} saveAsDefault={handleSaveAsDefault} />
              )}
            </Stack>
            <Typography variant="h3" sx={{ fontSize: '1.25rem', mt: '1rem' }}>Add new Team View</Typography>
            <TextField
              label="Add description"
              value={viewDescription}
              type="password"
              onChange={(e) => setViewDescription(e.target.value)}
              onSubmit={handleSaveTeamView}
              disabled={loading}
              minRows={3}
              multiline
            />
            <Stack direction='row' justifyContent='flex-start' alignItems='center'>
              <Checkbox checked={viewIsDefault} onChange={() => setViewIsDefault(!viewIsDefault)} sx={{ color: 'white' }} />
              <Typography variant="h6" sx={{ fontSize: '1rem' }}>Save as default view for your team</Typography>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Button variant="contained" sx={{ margin: 'auto' }} onClick={handleSaveTeamView}>
            SAVE CURRENT FILTERS AS TEAM VIEW {loading &&
              <CircularProgress sx={{
                color: "white",
                height: "1rem !important",
                width: "1rem !important",
                marginLeft: "1rem"
              }} />
            }
          </Button>
        </CardActions>
      </Card>
    </Modal >
  );
};

export default TeamViewsModal;
