/* eslint-disable @typescript-eslint/no-explicit-any */
// Libraries
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { createLogger } from 'redux-logger';
import ReduxQuerySync from 'redux-query-sync';

// Custom
import appReducer from './reducers';
import sagas from './sagas';
import IStore from './models';
import { EAppActionTypes } from 'storage/app/models';
import { history } from 'routes';
import { StringItem } from 'components/FilterBar/FilterMenu';
import { noActivitySinceOptions } from 'components/FilterBar/SubFilters';

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

const middlewares: Array<any> = [sagaMiddleware];

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true,
  });
  middlewares.push(logger);
}

const composeEnhancers: typeof compose =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore<any, any, any, any>(
  appReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

ReduxQuerySync({
  store,
  params: {
    /*dateRange: {
      selector: (state: IStore) => state.app.filters.dateRange,
      action: (value: string) => ({
        type: EAppActionTypes.SET_DATE_RANGE,
        payload:
          dateOptions.find((opt) => opt.value === value) || dateOptions[0],
      }),
      stringToValue: (str: string) => str || undefined,
      valueToString: (value?: StringItem) => value?.value || undefined,
      defaultValue: undefined,
    },*/
    zoom: {
      selector: (state: IStore) => state.app.zoom,
      action: (value: number) => ({
        type: EAppActionTypes.SET_ZOOM,
        payload: value,
      }),
      stringToValue: (str: string) => parseFloat(str) || undefined,
      valueToString: (value: number) => `${value}`,
      defaultValue: 4,
    },
    lat: {
      selector: (state: IStore) => state.app.lat,
      action: (value: number) => ({
        type: EAppActionTypes.SET_LAT,
        payload: value,
      }),
      stringToValue: (str: string) => parseFloat(str) || undefined,
      valueToString: (value: number) => `${value}`,
      defaultValue: 36.1845556529244,
    },
    lng: {
      selector: (state: IStore) => state.app.lng,
      action: (value: number) => ({
        type: EAppActionTypes.SET_LNG,
        payload: value,
      }),
      stringToValue: (str: string) => parseFloat(str) || undefined,
      valueToString: (value: number) => `${value}`,
      defaultValue: -100.39327200908463,
    },
    region: {
      selector: (state: IStore) => state.app.filters.region,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_REGION,
        payload: value,
      }),
      stringToValue: (str: string) => str.split(',') || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    market: {
      selector: (state: IStore) => state.app.filters.market,
      action: (value: number[]) => ({
        type: EAppActionTypes.SET_MARKET,
        payload: value,
      }),
      stringToValue: (str: string) =>
        str.split(',').map((v) => parseInt(v)) || undefined,
      valueToString: (value: number[]) =>
        value && value.length > 0
          ? value.reduce(
              (prev, current) => (prev ? `${prev},${current}` : `${current}`),
              '',
            )
          : [],
      defaultValue: undefined,
    },
    club: {
      selector: (state: IStore) => state.app.filters.club,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_CLUB,
        payload: value,
      }),
      stringToValue: (str: string) => str.split(',') || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    workorders: {
      selector: (state: IStore) => state.app.filters.workorders,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'workorders', value },
      }),
      stringToValue: (str: string) =>
        str.split(',').map((id) => parseInt(id)) || undefined,
      valueToString: (value: number[]) =>
        value !== undefined
          ? value.reduce(
              (prev: string, current) =>
                prev ? `${prev},${current}` : `${current}`,
              '',
            )
          : [],
      defaultValue: undefined,
    },
    trades: {
      selector: (state: IStore) => state.app.filters.trades,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'trades', value },
      }),
      stringToValue: (str: string) => str.split(',') || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    tradeGroups: {
      selector: (state: IStore) => state.app.filters.tradeGroups,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'tradeGroups', value },
      }),
      stringToValue: (str: string) => str.split(',') || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    providers: {
      selector: (state: IStore) => state.app.filters.providers,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'providers', value },
      }),
      stringToValue: (str: string) => str.split('\\-/') || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev}\\-/${current}`)
          : [],
      defaultValue: undefined,
    },
    categories: {
      selector: (state: IStore) => state.app.filters.categories,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'categories', value },
      }),
      stringToValue: (str: string) =>
        (str !== '' && str.split(',')) || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    status: {
      selector: (state: IStore) => state.app.filters.status,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'status', value },
      }),
      stringToValue: (str: string) =>
        (str !== '' && str.split(',')) || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: ['OPEN', 'IN PROGRESS'],
    },
    bsi: {
      selector: (state: IStore) => state.app.filters.bsi,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'bsi', value },
      }),
      stringToValue: (str: string) =>
        (str !== '' && str.split(',')) || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    nte: {
      selector: (state: IStore) => state.app.filters.nte,
      action: (value: StringItem) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'nte', value },
      }),
      stringToValue: (str: string) =>
        str !== ''
          ? str === 'greaterThanZero'
            ? { name: 'NTE > $0', value: 'greaterThanZero' }
            : { name: 'NTE = $0', value: 'equalsZero' }
          : undefined,
      valueToString: (value: StringItem) => (value ? value.value : undefined),
      defaultValue: undefined,
    },
    workingOnIt: {
      selector: (state: IStore) => state.app.filters.workingOnIt,
      action: (value: StringItem) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'workingOnIt', value },
      }),
      stringToValue: (str: string) =>
        str !== ''
          ? str === 'true'
            ? { name: 'Active', value: true }
            : { name: 'Not active', value: false }
          : undefined,
      valueToString: (value: StringItem) => (value ? value.value : undefined),
      defaultValue: undefined,
    },
    escalated: {
      selector: (state: IStore) => state.app.filters.escalated,
      action: (value: StringItem) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'escalated', value },
      }),
      stringToValue: (str: string) =>
        str !== ''
          ? str === 'true'
            ? { name: 'Active', value: true }
            : { name: 'Not active', value: false }
          : undefined,
      valueToString: (value: StringItem) => (value ? value.value : undefined),
      defaultValue: undefined,
    },
    notOperationalOnly: {
      selector: (state: IStore) => state.app.filters.notOperationalOnly,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'notOperationalOnly', value },
      }),
      stringToValue: (str: string) => (str ? true : false),
      valueToString: (value: string) => (value ? value : ''),
      defaultValue: undefined,
    },
    potentialDuplicateOnly: {
      selector: (state: IStore) => state.app.filters.potentialDuplicateOnly,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'potentialDuplicateOnly', value },
      }),
      stringToValue: (str: string) => (str ? true : false),
      valueToString: (value: string) => (value ? value : ''),
      defaultValue: undefined,
    },
    missedETA: {
      selector: (state: IStore) => state.app.filters.missedETA,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'missedETA', value },
      }),
      stringToValue: (str: string) => (str ? true : false),
      valueToString: (value: string) => (value ? value : ''),
      defaultValue: undefined,
    },
    maintenanceRepairOnly: {
      selector: (state: IStore) => state.app.filters.maintenanceRepairOnly,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'maintenanceRepairOnly', value },
      }),
      stringToValue: (str: string) => (str ? true : false),
      valueToString: (value: string) => (value ? value : ''),
      defaultValue: undefined,
    },
    workingOnItUser: {
      selector: (state: IStore) => state.app.filters.workingOnItUser,
      action: (value: string) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'workingOnItUser', value },
      }),
      stringToValue: (str: string) => (str !== '' && str) || undefined,
      valueToString: (value: string) => (value ? value : undefined),
      defaultValue: undefined,
    },
    escalatedUser: {
      selector: (state: IStore) => state.app.filters.escalatedUser,
      action: (value: string) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'escalatedUser', value },
      }),
      stringToValue: (str: string) => (str !== '' && str) || undefined,
      valueToString: (value: string) => (value ? value : undefined),
      defaultValue: undefined,
    },
    noActivitySince: {
      selector: (state: IStore) => state.app.filters.noActivitySince,
      action: (value: StringItem) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'noActivitySince', value },
      }),
      stringToValue: (str: string) =>
        (str !== '' &&
          noActivitySinceOptions.find((opt) => opt.name === str)) ||
        undefined,
      valueToString: (value: StringItem) => (value ? value.name : undefined),
      defaultValue: undefined,
    },
    equipmentTypes: {
      selector: (state: IStore) => state.app.filters.equipmentTypes,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'equipmentTypes', value },
      }),
      stringToValue: (str: string) =>
        (str !== '' && str.split(',')) || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    extendedStatus: {
      selector: (state: IStore) => state.app.filters.extendedStatus,
      action: (value: string[]) => ({
        type: EAppActionTypes.SET_FILTER,
        payload: { key: 'extendedStatus', value },
      }),
      stringToValue: (str: string) =>
        (str !== '' && str.split(',')) || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    heatmapCell: {
      selector: (state: IStore) =>
        state.app.filters.importance && state.app.filters.urgency
          ? [state.app.filters.importance, state.app.filters.urgency]
          : undefined,
      action: (value: [number, number]) => ({
        type: EAppActionTypes.SELECT_HEATMAP_CELL,
        payload: value
          ? { importance: value[0], urgency: value[1] }
          : undefined,
      }),
      stringToValue: (str: string) =>
        (str !== '' && str.split(',').map((s) => parseInt(s))) || undefined,
      valueToString: (value: string[]) =>
        value && value.length > 0
          ? value.reduce((prev, current) => `${prev},${current}`)
          : [],
      defaultValue: undefined,
    },
    workorder: {
      selector: (state: IStore) => state.app.filters.workorder,
      action: (value: number) => ({
        type: EAppActionTypes.SET_WORKORDER,
        payload: value,
      }),
      stringToValue: (str: string) => parseInt(str) || undefined,
      valueToString: (value: number) => `${value}`,
      defaultValue: undefined,
    },
    sort_field: {
      selector: (state: IStore) => state.app.sortField,
      action: (value: string) => ({
        type: EAppActionTypes.SET_SORT_FIELD,
        payload: value,
      }),
      defaultValue: 'combined_index',
    },
    sort_asc: {
      selector: (state: IStore) => state.app.sortAsc,
      action: (value: boolean) => ({
        type: EAppActionTypes.SET_SORT_ASC,
        payload: value,
      }),
      stringToValue: (str: string) => (str === 'true' ? true : false),
      valueToString: (value: number) => `${value}`,
      defaultValue: false,
    },
  },
  initialTruth: 'location',
  history: history,
});

sagaMiddleware.run(sagas);

export default store;
